import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import './assets/scss/core.scss';
import './assets/scss/common.scss';
import './assets/scss/bootstrap.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import routes from './router/index';
import { initReduxStore, store } from './store/index';

initReduxStore((err: any, state: any) => {
  if (!err) {
    renderApp();
  } else {
    //Incompatible browser version
  }
});
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
renderApp();
export function renderApp() {
  root.render(
    <React.StrictMode>
      <Provider store={store as any}>
        <Router>
          {routes}
        </Router>
      </Provider>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
