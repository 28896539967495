import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import '../assets/scss/components/login.scss';
import { IAppStore } from '../reducers';
import withRouter from '../withRouter';
import { utility } from '../utils';
import { login } from '../services/user_service';
import { app_actions } from '../actions/app_actions';

interface IRouter {
    location: any;
    navigate: any;
    params: any;
}
interface ILoginProps {
    location?: any;
    Dispatch: Dispatch<Action>;
    authToken?: string;
    router?: IRouter;
}
interface ILoginState {
    authToken?: string;
}

const Login = (props: ILoginProps, state: ILoginState) => {
    const [fieldValidationErrors, setFieldValidationErrors] = useState<any>({});
    const [errorMessage, setErrorMessage] = useState<any>();
    const userEmail = useRef<HTMLInputElement>();
    const userPassword = useRef<HTMLInputElement>();
    let fieldValidationErrorsVal: any = {};

    const loginHandler = async () => {
        setErrorMessage('');
        const validationResultEmail = validateField('email', userEmail?.current?.value);
        const validationResultPassword = validateField('password', userPassword?.current?.value);
        if (!validationResultEmail && !validationResultPassword) {
            const loginPayLoad = {
                'userName': userEmail.current.value,
                'password': userPassword.current.value,
                'grant_type': 'password'
            }
            const result = await login(loginPayLoad);
            if (result.status !== 200) {
                setErrorMessage(result.data?.error_description);
            } else {
                props.Dispatch(app_actions.storeToken(result.data?.token));
                props.router.navigate('/');
            }
        }
    }

    const validateField = (fieldName, value) => {
        let emailValid;
        let passwordValid;
        switch (fieldName) {
            case 'email':
                /* eslint-disable no-useless-escape */
                const regexp: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                emailValid = value.match(regexp);
                fieldValidationErrorsVal.email = emailValid ? '' : ' Invalid Email Address';
                break;
            case 'password':
                passwordValid = value.length >= 3;
                fieldValidationErrorsVal.password = passwordValid ? '': ' Min 3 characters required';
                break;
            default:
                break;
        }
        setFieldValidationErrors(fieldValidationErrorsVal);
        return (!fieldValidationErrorsVal.email && !fieldValidationErrorsVal.password) ? false : true;
    }

    return (
        <section className='vh-100 main-bgimg'>
            <div className="container-fluid loginscreen">
		<div className="row centerele">
            {/* <div className='container-fluid h-custom'>
                <div className='row  h-100'> */}
                <div className='col-sm-4'>
                    </div>                   
                    <div className='col-sm-4 col-12'>
                    <div className="frm-wrap">
					<div className="pwclogin-frm-wrapper">

						<h5 className="frm-header">Login Page</h5>
                        <form className={`${Object.keys(fieldValidationErrors).length > 0 ? 'was-validated' : ''}`}>

                            {errorMessage && <div className="error mt-2 text-center frm-vlmsg">{errorMessage}</div>}
                            <div className='  form-group'>
                                <label className='form-label frmlabel'>Email address</label>
                                <div className="input-group has-validation">
                                    <input onChange={() => {
                                        fieldValidationErrorsVal = {};
                                        setFieldValidationErrors({});
                                    }} ref={userEmail} type='email' id='form3Example3' className={`form-control  frm-control ${fieldValidationErrors?.email ? 'is-invalid' : ''}`}
                                        placeholder='Enter a valid email address' />
                                    {fieldValidationErrors?.email && <div className="invalid-feedback validmsg">
                                        {fieldValidationErrors?.email}
                                    </div>}
                                </div>
                            </div>

                            <div className='  form-group'>
                                <label className='form-label frmlabel'>Password</label>
                                <div className="input-group has-validation">
                                    <input onChange={() => {
                                        fieldValidationErrorsVal = {};
                                        setFieldValidationErrors({});
                                    }} minLength={3} ref={userPassword} type='password' id='form3Example4' className={`form-control frm-control ${fieldValidationErrors?.password ? 'is-invalid' : ''}`}
                                    placeholder='Enter password' />
                                    {fieldValidationErrors?.password && <div className="invalid-feedback validmsg">
                                        {fieldValidationErrors?.password}
                                    </div>}
                                </div>
                                
                            </div>
                            <div className='text-center text-lg-start mt-4 pt-2'>
                                <button onClick={() => {
                                    loginHandler()
                                }} type='button' className='btn loginbtn core-btn'>LOGIN</button>
                            </div>

                        </form>
                        <div className="text-center">
							<p className="pwdbytext">Powered By <img src={require('../assets/images/logo/memss-logo.jpg')} alt="memss-logo" /></p>
						</div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
function mapStoreToProps(store: IAppStore) {
    return {
        authToken: store.App.authToken
    };
}
export default withRouter(connect(mapStoreToProps, utility.mapDispatchToProps)(Login) as React.ComponentType<any>);
