import {
    applyMiddleware, compose, createStore, Middleware
} from 'redux';
import { asyncLocalStorage, asyncSessionStorage } from 'redux-persist/storages';
import thunk from 'redux-thunk';
import promiseMiddleware from '../middleware/promise-middleware';
import Reducer, { IAppStore } from './../reducers/index';


const __DEV__ = true;

function configureStore(initialState: any) {
    const _store = createStore(
        Reducer,
        __DEV__
            ? window['__REDUX_DEVTOOLS_EXTENSION__'] && window['__REDUX_DEVTOOLS_EXTENSION__']() // this will enable redux dev toos extension in browser
            : initialState,
        compose(
            applyMiddleware(..._getMiddleware())));

    _enableHotLoader(_store);
    return _store;
}

function _getMiddleware(): Middleware[] {
    let middleware = [
        promiseMiddleware,
        thunk
    ];

    if (__DEV__) {
        middleware = [...middleware];
    }

    return middleware;
}

function _enableHotLoader(_store: any) {
    if (!__DEV__) {
        return;
    }

    const { hot } = module as any;
    if (hot) {
        hot.accept('./../reducers', () => {
            const nextRootReducer = require('./../reducers');
            _store.replaceReducer(nextRootReducer);
        });
    }
}

export const store: {
    dispatch: any,
    getState: () => IAppStore,
    subscribe: any;
} = configureStore({});

export const persistStore = require('redux-persist').persistStore;

export function initReduxStore(cb) {
    persistStore(store, {
        storage:  (window.localStorage ? asyncLocalStorage : asyncSessionStorage)
    }, (err: any, state: any) => {
        if (!err) {
            cb();
        }
    });
}

// Listen to store changes and wanr user if offline storage is used abovce 4 MB
// After JSON stringify each char in string will occupy 1 byte space. Verified by exporing complete store to json file
store.subscribe(function () {
    // retrieve latest store state here
    // Ex:
});
