import _ from '../utils/_';
import * as Constants from '../actions/constants';
export interface IAppState {
    authToken?: string;
}
const initialState: IAppState = {
    authToken: ''
};
export function App(state: IAppState = initialState, { type, payload }: any): IAppState {
    switch (type) {
        case 'persist/REHYDRATE': {
            return _.extend({}, state, payload.App);
        }
        case Constants.AUTH_TOKEN: {
            return _.extend({}, state, { authToken: payload });
        }
        default: return state;
    }
}
