import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = (props) => {
    const navigate = useNavigate();
    const renderPageData = () => {
        return (
            <div id='page-not-found'>
                <div className='container'>
                    <h1>404 Page not found</h1>
                    <p>We are unable to find the page you are looking for.</p>
                    <button onClick={() => { navigate(-1); }}>Go Back</button>
                </div>
            </div>
        );
    };
    return (
        <div>
            {renderPageData()}
        </div>
    );
};
export default PageNotFound;