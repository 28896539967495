import { Dispatch } from 'redux';
import { IUtility } from './utility';
let util;
class Utility implements IUtility {
    Window = {
        GetViewPort(): { width: number, height: number } {
            return {
                width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
            };
        }
    };
    mapDispatchToProps(dispatch: Dispatch<any>): { Dispatch: Dispatch<any> } {
        return {
            Dispatch: dispatch
        };
    }
    logout() {
        localStorage.clear();
        window.location.reload();
    }
}
util = new Utility();
export const utility = util;