import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import '../assets/scss/components/header.scss';
import { IAppStore } from '../reducers';
import withRouter from '../withRouter';
import { utility } from '../utils';
//import { app_actions } from '../actions/app_actions';

interface IHeaderProps {
  location?: any;
  Dispatch: Dispatch<Action>;
  authToken?: string;
}
interface IHeaderState {
  authToken?: string;
}

const Header = (props: IHeaderProps, state: IHeaderState) => {
  const [authTokenVal, setauthTokenVal] = useState<string>('');

  useEffect(() => {
    console.log('Header Component')
  }, []);

  useEffect(() => {
    setauthTokenVal(props.authToken);
  }, [props.authToken]);

  return (
    authTokenVal ? <nav className='navbar navbar-light bg-light'>
      <div className='navbar-brand px-4'>
          MEMSS
      </div>
    </nav> : <></>
  );
};
function mapStoreToProps(store: IAppStore) {
  return {
    authToken: store.App.authToken
  };
}
export default withRouter(connect(mapStoreToProps, utility.mapDispatchToProps)(Header) as React.ComponentType<any>);
