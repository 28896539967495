import { API_BASE_URL, LOGIN } from './end_points';
export async function login(payload) {
  let formBody = [];
  for (let property in payload) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  const formBodyPayload = formBody.join('&');
  const response = await fetch(API_BASE_URL+LOGIN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: formBodyPayload
  });
  const data = await response.json();
  const serverStatusCode = await response.status;
  return {
    status: serverStatusCode,
    data
  };
}