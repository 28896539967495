import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/header';
import Login from '../pages/login';
import PageNotFound from '../pages/page_not_found';
import * as Pages from './page_constants';
import App from '../App';

export default (
    <React.Suspense fallback={<div>Loading...</div>}>
        <div id='body-div'>
            <Header />
            <section className='body_container'>
                <Routes>
                    <Route path='/' element={<App />}></Route>
                    <Route path={Pages.PageNotFound} element={<PageNotFound />}></Route>
                    <Route path={Pages.Login} element={<Login />}></Route>
                    <Route path={'*'} element={<PageNotFound />}></Route>
                </Routes>
            </section>
        </div>
    </React.Suspense>
);
