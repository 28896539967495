import * as Constants from './constants';

class AppActions {
    storeToken = (token: string) => {
        return {
            type: Constants.AUTH_TOKEN,
            payload: token
        };
    }
    profileInfo(data: any) {
        return {
            type: Constants.LOGIN_DATA,
            payload: data
        };
    }
}

export let app_actions = new AppActions();
