import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from './reducers';
import withRouter from './withRouter';
import { utility } from './utils';

const App = (props) => {
  const [authTokenVal, setauthTokenVal] = useState<string>('');

  useEffect(() => {
    //console.log(props.authToken, 'authTOken');
    setauthTokenVal(props.authToken);
  }, [props.authToken]);

  return (
    <div className="App">
      <p className='text-center'>React PWA</p>
      <div className='d-flex justify-content-center row'>
        {authTokenVal && <h4 className='text-center my-2'>Token : {authTokenVal}</h4>}
        {authTokenVal && <button onClick={() => {
          utility.logout();
        }} className='col-2 btn btn-danger my-4'>Logout</button>}
        {!authTokenVal && <button onClick={() => {
          props.router.navigate('/login')
        }} className='col-2 btn btn-success'>Login</button>}
      </div>
    </div>
  );
}
function mapStoreToProps(store: IAppStore) {
  return {
    authToken: store.App.authToken
  };
}
export default withRouter(connect(mapStoreToProps, utility.mapDispatchToProps)(App) as React.ComponentType<any>);