const _ = {
    filter: require('lodash/filter'),
    map: require('lodash/map'),
    each: require('lodash/each'),
    find: require('lodash/find'),
    concat: require('lodash/concat'),
    max: require('lodash/max'),
    isEmpty: require('lodash/isEmpty'),
    cloneDeep: require('lodash/cloneDeep'),
    remove: require('lodash/remove'),
    forEach: require('lodash/forEach'),
    clone: require('lodash/clone'),
    orderBy: require('lodash/orderBy'),
    extend: require('lodash/extend'),
    sortBy: require('lodash/sortBy'),
    startCase: require('lodash/startCase'),
    toLower: require('lodash/toLower'),
    findIndex: require('lodash/findIndex'),
    uniqBy: require('lodash/uniqBy')
};

export default _;